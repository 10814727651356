@use '../common/sassVars' as *;
@use '../mixins/border-arrow' as *;

%flickity-carousel {
	&.is-hidden {
		height: var(--prevload-height);
		background: var(--grey-100) url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzgiIGhlaWdodD0iMzgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgc3Ryb2tlPSIjY2NjIj48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIiBzdHJva2Utd2lkdGg9IjIiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+PGNpcmNsZSBzdHJva2Utb3BhY2l0eT0iLjUiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIvPjxwYXRoIGQ9Ik0zNiAxOGMwLTkuOTQtOC4wNi0xOC0xOC0xOCI+PGFuaW1hdGVUcmFuc2Zvcm0gYXR0cmlidXRlTmFtZT0idHJhbnNmb3JtIiB0eXBlPSJyb3RhdGUiIGZyb209IjAgMTggMTgiIHRvPSIzNjAgMTggMTgiIGR1cj0iMXMiIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIi8+PC9wYXRoPjwvZz48L3N2Zz4=') no-repeat center;

		.carousel {
			opacity: 0;
		}
	}

	.carousel {
		@media (min-width: $scr-sm-min) {
			&::after {
				content: 'flickity';
				display: none;
			}
		}

		@media (max-width: $scr-sm) {
			&::after {
				content: '';
			}
		}
	}

	.flickity-button {
		width: var(--flickity-nav-size);
		height: var(--flickity-nav-size);
		background-color: #fff;
		border: 1px solid var(--grey-400);
		z-index: 1;

		&:hover {
			border-color: #000;
		}


		&.previous,
		&.next {
			&::after {
				position: relative;
				top: 1px;
			}
		}


		&.previous {
			left: calc((var(--flickity-nav-size) / 2) * -1);

			&::after {
				@include border-arrow(left, var(--flickity-nav-svg-size), var(--flickity-nav-svg-size));

				left: -1px;
			}
		}

		&.next {
			right: calc((var(--flickity-nav-size) / 2) * -1);

			&::after {
				@include border-arrow(right, var(--flickity-nav-svg-size), var(--flickity-nav-svg-size));

				left: 1px;
			}
		}

		&:disabled {
			display: none;
		}

		svg {
			display: none;
		}

		&:focus {
			box-shadow: none;
		}
	}

	.flickity-page-dots {
		bottom: -20px;

		.dot {
			width: 9px;
			height: 9px;
			margin: 0 5px;
			background-color: transparent;
			box-shadow: 0 0 0 1px var(--grey-800) inset;
			opacity: 1;

			&:only-child {
				display: none;
			}

			&.is-selected {
				background-color: var(--grey-800);
			}
		}
	}
}
