@use "../designSystem/common/sassVars" as *;
@use "../../../node_modules/flickity/dist/flickity.css";
@use "../designSystem/exports/flickityCarouselDesktop";

.ds-horizontalCardList {
	--prevload-height: 414px;
	--flickity-nav-size: 38px;
	--flickity-nav-svg-size: 14px;

	@extend %flickity-carousel;

	.carousel-cell {
		padding: 20px;
		margin-bottom: 20px;

		&.ds-horizontalCardList__imageOnly {
			min-height: var(--prevload-height);

			&.visible::before {
				content: normal;
			}
		}

		@media (min-width: $scr-sm-min) {
			min-height: var(--prevload-height);
			width: 310px;
			margin-left: 30px;
			margin-bottom: 5px;
		}
	}

	a {
		display: grid;
		grid-template-rows: 80px auto auto;
		text-align: center;
		align-items: center;
		row-gap: 10px;
		background-color: var(--grey-500);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		color: #fff;
		border-radius: var(--rad);
		padding: 20px;
		position: relative;
		overflow: hidden;

		& > * {
			position: relative;
			z-index: 2;
		}

		&.visible {
			background-image: var(--listbg-url);

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgb(0 0 0 / 40%);
				backdrop-filter: blur(3px);
				z-index: 1;
			}
		}

		@media (min-width: $scr-lg-min) {
			grid-template-rows: 120px auto auto;
			row-gap: 20px;
			padding: 30px;
		}

		img {
			max-height: 60px;
			display: block;
			margin: auto;

			@media (min-width: $scr-lg-min) {
				max-height: 100px;
			}

		}

		b {
			font-size: 20px;
			line-height: 26px;
			text-transform: uppercase;

			@media (min-width: $scr-lg-min) {
				font-size: 24px;
				line-height: 30px;
			}
		}

		p {
			margin: 0;
		}
	}

	&--nocarousel {
		display: grid;
		margin-bottom: var(--margin-l);

		@media (max-width: $scr-sm) {
			gap: 20px;
		}

		@media (min-width: $scr-sm-min) {
			grid-template-columns: repeat(3, 1fr);
			gap: 30px;
		}
	}

}
